$ripple-animation-length: 5s;
$ripple-animation-delay: 150ms;
$ripple-scale-size: 0.025;
$ripple-bg: rgba(255, 255, 255, 0.1);

@font-face {
	font-family: "Local Open Sans";
	src: url("./OpenSans-SemiBold.ttf");
}

* {
	// font-family: "VarelaRound-Regular" !important;
	font-family: "Open Sans", "Local Open Sans", sans-serif !important;
}
.font-open-sans {
	font-family: "Open Sans", "Local Open Sans", sans-serif !important;
}

.ripples > div {
	position: absolute;
	border-radius: 50%;
	color: transparent;
	transform-origin: center;

	@for $i from 5 through 1 {
		&:nth-child(#{$i}) {
			width: (100% / 5) * $i;
			height: (100% / 5) * $i;
			z-index: 10 - $i;
			animation: (ripple + $i) $ripple-animation-length infinite ease-in-out;
			animation-delay: (1000ms - ($ripple-animation-delay * $i));
		}
	}
}

#credit {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 1rem;
	font-family: "Open Sans", "Local Open Sans", sans-serif !important;
	// font-family: helvetica,arial,sans-serif!important;
	font-size: 10px;
	color: #12102c;
}

#one {
	// background-color: $ripple-bg;
}

@for $i from 5 through 1 {
	@keyframes #{ripple+$i} {
		0% {
			transform: scale(1);
		}
		25% {
			// transform: scale(1.1 + ($ripple-scale-size * $i));
		}
		50% {
			// transform: scale(8 - (.5 * $i));
		}
		75% {
			// transform: scale(1.1 + ($ripple-scale-size * $i));
		}
		100% {
			transform: scale(1);
		}
	}
}

#five {
	background-color: $ripple-bg;
	animation: warn1 2.5s linear;
	animation-iteration-count: infinite;
}

@keyframes warn1 {
	0% {
		transform: scale(1);
		opacity: 0.3;
	}
	25% {
		transform: scale(2);
		opacity: 0.3;
	}
	50% {
		transform: scale(3);
		opacity: 0.4;
	}
	75% {
		transform: scale(4);
		opacity: 0.5;
	}
	100% {
		transform: scale(5);
		opacity: 0.6;
	}
}
