.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.relative.flex > .locator.flex.justify-center.items-center.absolute {
	top: 28%;
}
.front-view .locator.flex.justify-center.items-center.absolute {
	left: 50% !important;
}
@media screen and (max-width: 1536px) {
	.relative.flex > .locator.flex.justify-center.items-center.absolute {
		top: 28%;
		right: 28%;
		left: 60%;
		transform: translate(-50%, 0%);
	}
	.front-view .locator.flex.justify-center.items-center.absolute {
		left: 50% !important;
	}
}

@media screen and (max-width: 767px) and (orientation: portrait) {
	/* //竖屏 */
	.relative.flex > .pad {
		width: 75%;
		height: 35vh;
		/* overflow: hidden; */
		left: 53%;
		transform: translate(-50%, 0%);
	}
	.relative.flex > .flex.justify-center.items-center.absolute {
		top: 50%;
		right: 45%;
	}
	.relative.flex > .locator.flex.justify-center.items-center.absolute {
		top: 50%;
		right: 28%;
		left: 50%;
		transform: translate(-50%, 0%);
	}
	.pad-svg {
		clip-path: url(#clip-pole);
	}
}
@media screen and (max-device-width: 767px) and (orientation: landscape) {
	/* //横屏 */
	.relative.flex > .pen__wrapper {
		bottom: -26rem;
	}
	.pad-svg {
		clip-path: url(#clip-pole);
	}
}

@media (max-width: 600px) {
	.metal-locator {
		width: 60%;
		left: 15%;
	}
	#three {
		width: 120px;
	}
}

.MuiDialog-paper, .MuiDialog-root.MuiModal-root {
	z-index: 9999999991;
}

.view-change-wrap {
	z-index: 999999999;
}
.view-change-wrap button {
	padding: 10px 15px;
	border: 2px solid  #04B6ED;
	color: #ffffff;
	white-space: nowrap;
	z-index: 999999999;
}

.view-change-wrap .button-left {
	border-radius: 20px 0 0 20px;
	border-right: 0;
}

.view-change-wrap .button-right {
	border-radius: 0 20px 20px 0;
	border-left: 0;

}

.view-change-wrap .button-left.active {
	border-right: 2px solid  #04B6ED;
}

.view-change-wrap .button-right.active {
	border-left: 2px solid  #04B6ED;
}

.view-change-wrap button:hover {
	border-color: white;
	color: #fff;
}

.view-change-wrap button.active {
	border-color:  #04B6ED;
	font-weight: 900;
	background-color:  #04B6ED;
}

.sideview-pen {
	overflow: visible;
}

/* @media only screen and (min-width: 640px) {
	.pad {
		top: 3vh !important;
	}
	.relative.flex > .locator.flex.justify-center.items-center.absolute {
		top: 28vh !important;
	}
} */

@media only screen and (max-width: 640px) {
	.app-v2 .pad {
		top: 1svh !important;
		height: 330px !important;
		width: auto !important;
	}

	.front-view.app-v2 .pad svg {
		height: auto;
		width: 39svh !important;
	}

	.side-view.app-v2 .pad svg {
		height: auto;
		width: 39svh !important;
	}

	/* .front-view .view-change-wrap {
		top: 36svh !important;
		bottom: auto !important;
	} */

	.front-view .view-change-wrap,
	.side-view .view-change-wrap {
		top: auto !important;
		bottom: 7svh !important;
	}

	.view-change-wrap button {
		font-size: 22px;
		padding: 8px 12.5px;

	}
	.view-change-wrap button {
		background-color: #0033a1;
		border: 2px solid  #04B6ED;
		color: #ffffff;
	}

	.view-change-wrap .button-left.active {
		border-right: 2px solid  #04B6ED;
	}
	
	.view-change-wrap .button-right.active {
		border-left: 2px solid  #04B6ED;
	}
	
	.view-change-wrap button:hover {
		border-color:  #04B6ED;
	}
	
	.view-change-wrap button.active {
		border-color:  #04B6ED;
		font-weight: 900;
		background-color:  #04B6ED;
	}

	.app-v2 .relative.flex > .locator.flex.justify-center.items-center.absolute{
		left: 10svw !important;
	}
	.front-view .relative.flex > .locator.flex.justify-center.items-center.absolute{
		left: 50svw !important;
	}
}

@media screen and (max-width: 640px) and (orientation: portrait) {

	.app-v2 .relative.flex > .locator.flex.justify-center.items-center.absolute {
		top: 37svh;
	}
	.app-v2.front-view .relative.flex > .locator.flex.justify-center.items-center.absolute {
		top: 39svh;
	}
}